import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import BlockContent from "../components/BlockContent/BlockContent";
import Breadcrumbs, { Breadcrumb } from "../components/Breadcrumbs/Breadcrumbs";
import Card from "../components/Card/Card";
import CardTrio from "../components/CardTrio/CardTrio";
import DybProjectCard from "../components/DybProjectCard/DybProjectCard";
import EditorEmbed from "../components/EditorEmbed/EditorEmbed";
import EditorEmbedSupport from "../components/EditorEmbedSupport/EditorEmbedSupport";
import { Col, Row, Wrapper } from "../components/Grid";
import IsDesktop from "../components/IsDesktop/IsDesktop";
import IsMobile from "../components/IsMobile/IsMobile";
import MediaCarousel from "../components/MediaCarousel/MediaCarousel";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import WithSpacing from "../components/Spacing/Spacing";
import Text from "../components/Text/Text";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, MicrobitCode, SanityPage } from "../model/common";
import { SanityDybProject } from "../model/impact";
import { GlobalMenus } from "../model/menu";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import { getYearFromDate } from "../utils/dates";
import { imageUrlBuilder } from "../utils/images";
import styles from "./DybProjectPage.module.scss";

export const pageQuery = graphql`
  query DybProjectPage(
    $_id: String!
    $locationId: String!
    $globalGoalsIds: [String!]!
    $language: String
    $navLanguage: String
  ) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    dybProjectListingPage: sanityDybProjectListingPage(
      language: { eq: $language }
    ) {
      _id
      _type
      title
    }
    page: sanityDybProject(_id: { eq: $_id }) {
      _id
      _type
      title
      subtitle
      projectInformation: _rawProjectInformation(
        resolveReferences: { maxDepth: 5 }
      )
      fields {
        editorLinks
        locationId
      }
      partner {
        name
      }
      date
      authors
      projectInspiration: _rawProjectInspiration(
        resolveReferences: { maxDepth: 5 }
      )
      image {
        ...SanitySimpleImage
        ...SanityHotspotCrop
      }
      additionalMedia {
        ...SanitySimpleImage
        ...SanityHotspotCrop
        ... on SanityYoutubeVideo {
          _type
          youtubeId
          alt
        }
      }
      globalGoals {
        number
        name
        icon {
          ...SanityMinimalImage
        }
      }
      location {
        ... on SanityCountry {
          _id
        }
        ... on SanityRegion {
          _id
        }
      }
      slug {
        current
      }
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    relatedOne: sanityDybProject(
      _id: { ne: $_id }
      globalGoals: { elemMatch: { _id: { in: $globalGoalsIds } } }
    ) {
      ...DybProjectCard
    }
    relatedTwo: sanityDybProject(
      _id: { ne: $_id }
      globalGoals: { elemMatch: { _id: { nin: $globalGoalsIds } } }
      fields: { locationId: { eq: $locationId } }
    ) {
      ...DybProjectCard
    }
    relatedThree: sanityDybProject(
      _id: { ne: $_id }
      globalGoals: { elemMatch: { _id: { nin: $globalGoalsIds } } }
      fields: { locationId: { ne: $locationId } }
    ) {
      ...DybProjectCard
    }
  }
`;

interface DybProjectPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityDybProject;
    dybProjectListingPage: SanityPage;
    relatedOne: SanityDybProject;
    relatedTwo: SanityDybProject;
    relatedThree: SanityDybProject;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}

const DybProjectPage = ({
  pageContext,
  data: {
    menus,
    page,
    dybProjectListingPage,
    relatedOne,
    relatedTwo,
    relatedThree
  },
  location
}: DybProjectPageProps) => {
  const {
    title,
    subtitle,
    projectInformation,
    projectInspiration,
    authors,
    partner,
    date,
    globalGoals
  } = page;
  const relatedItems = [relatedOne, relatedTwo, relatedThree].filter(Boolean);
  const breadcrumbs: Breadcrumb[] = [
    {
      title: dybProjectListingPage.title,
      href: urlForDocument(dybProjectListingPage)
    },
    {
      title: page.title,
      href: urlForDocument(page)
    }
  ];
  const customEmbeds = {
    microbitCode: ({ node }: { node: MicrobitCode }) => (
      <EditorEmbed unit={undefined} code={node} context={page} />
    )
  };

  const hasProjectInformation =
    projectInformation &&
    Array.isArray(projectInformation) &&
    projectInformation.length > 0;
  const hasProjectInspiration =
    projectInspiration &&
    Array.isArray(projectInspiration) &&
    projectInspiration.length > 0;

  const mergedContent = [
    hasProjectInformation
      ? {
          _key: "our-project",
          _type: "block",
          children: [
            {
              _key: "our-project-heading",
              _type: "span",
              marks: [],
              text: "Our project"
            }
          ],
          markDefs: [],
          style: "h2"
        }
      : undefined,
    ...(hasProjectInformation ? projectInformation : []),
    hasProjectInspiration
      ? {
          _key: "our-inspiration",
          _type: "block",
          children: [
            {
              _key: "our-inspiration-heading",
              _type: "span",
              marks: [],
              text: "Our inspiration"
            }
          ],
          markDefs: [],
          style: "h2"
        }
      : undefined,
    ...(hasProjectInspiration ? projectInspiration : [])
  ].filter(Boolean);

  return (
    <EditorEmbedSupport document={page}>
      <PageLayout
        menus={menus}
        siteArea={SiteArea.IMPACT}
        metadata={{
          title,
          page,
          alternates: pageContext.alternates
        }}
        location={location}
        strings={pageContext.strings}
      >
        <Wrapper className={styles.root}>
          <Row justify="center">
            <Col lg={10} md={12} sm={12}>
              <Row justify="between" style={{ marginBottom: "10px" }}>
                <Col>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                </Col>
                <Col>
                  <SocialLinks title={title} />
                </Col>
              </Row>
              <Card type="main">
                <IsMobile>
                  <Row>
                    <Col md={12}>
                      <Text variant="h1">{title}</Text>
                    </Col>
                  </Row>
                </IsMobile>
                <Row className={styles.carouselInfoRow}>
                  <Col md={6} sm={6} xs={12} className={styles.carouselCol}>
                    <MediaCarousel
                      media={[
                        ...(page.image ? [page.image] : []),
                        ...page.additionalMedia
                      ]}
                    />
                  </Col>
                  <Col md={6} sm={6} xs={12} className={styles.info}>
                    <WithSpacing>
                      <div>
                        <IsDesktop>
                          <Text variant="h1">{title}</Text>
                        </IsDesktop>
                        <Text className={styles.subtitle} variant="h4" as="h2">
                          {subtitle}
                        </Text>
                        <Text variant="default">{partner.name}</Text>
                        {authors.length > 0 && (
                          <Text variant="default" as="p">
                            {authors.map(a => a.trim()).join(", ")}
                          </Text>
                        )}
                        <Text variant="default" as="p">
                          {getYearFromDate(date)}
                        </Text>
                        {globalGoals.length > 0 && (
                          <div className={styles.goals}>
                            <Text variant="defaultBold">Global goals</Text>
                            <div className={styles.goalIcons}>
                              {globalGoals.map(goal => (
                                <img
                                  width={100}
                                  src={
                                    imageUrlBuilder
                                      .image(goal.icon)
                                      .width(100)
                                      .height(100)
                                      .url()!
                                  }
                                  alt={goal.icon.alt}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </WithSpacing>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {mergedContent.length > 0 && (
            <Row justify="center">
              <Col lg={10} md={12} sm={12}>
                <Card type="main">
                  <BlockContent
                    customEmbeds={customEmbeds}
                    content={mergedContent}
                    cap="2/3"
                  />
                </Card>
              </Col>
            </Row>
          )}
          {relatedItems.length > 0 && (
            <Row justify="center" className={styles.relatedItems}>
              <Col md={10} sm={12}>
                <WithSpacing>
                  <Card type="main">
                    <Text variant="h2">You may also be interested in</Text>
                    <CardTrio
                      layout={relatedItems.length === 3 ? "stretch" : "tile"}
                    >
                      {relatedItems.map(project => (
                        <DybProjectCard key={project._id} project={project} />
                      ))}
                    </CardTrio>
                  </Card>
                </WithSpacing>
              </Col>
            </Row>
          )}
        </Wrapper>
      </PageLayout>
    </EditorEmbedSupport>
  );
};
export default DybProjectPage;
